// extracted by mini-css-extract-plugin
export var title1 = "typography-module--title1--3kWAX";
export var title2 = "typography-module--title2--3MCr0";
export var title3 = "typography-module--title3--3u4OD";
export var large = "typography-module--large--1Oqox";
export var base = "typography-module--base--2DtHq";
export var small = "typography-module--small--2jpCI";
export var micro = "typography-module--micro--2S5pr";
export var paragraph = "typography-module--paragraph--2ZibO";
export var blockQuote = "typography-module--blockQuote--_U6K9";
export var responsiveTitle1 = "typography-module--responsiveTitle1--2aak3";
export var responsiveTitle2 = "typography-module--responsiveTitle2--3J_AG";
export var responsiveTitle3 = "typography-module--responsiveTitle3--3W4yn";
export var responsiveTitle4 = "typography-module--responsiveTitle4--1LVBg";